import React, { useEffect, Fragment, useState } from "react";
import { Switch, Route } from "react-router-dom";
import {isMobile} from 'react-device-detect';

import {
  Home,
  About,
  Services,
  Contacts,
  ServiceSingle,
  LocalisationForm,
} from "./components/pages";
import { Header, Sidebar, Footer } from "./components/layouts";
import { ModalForm, ModalVideo, RequestForm } from "./components/ui";
import { Spinner } from "./components/elements";
import { useCustomState } from "./state/state";
import MessengerCustomerChat from 'react-messenger-customer-chat';
import TagManager from 'react-gtm-module'
import { Popup } from 'reactjs-popup';
export default () => {
  const [isPopupOpen, setIsPopupOpen] = useState(true);
  const [state, actions] = useCustomState();

  const tagManagerArgs = {
    gtmId: 'GTM-M5VDKRN'
  }

  TagManager.initialize(tagManagerArgs)

  useEffect(() => {
    actions.fetch();
  }, [actions]);

  let app = state.error ? <p>Can't load page</p> : <Spinner fluid />;

  if (state.data) {
    app = (
        <Fragment>
          <Sidebar data={state.data.menu} />
          <ModalForm />
          <RequestForm />
          <Header data={state.data.menu} />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/a-propos" exact component={About} />
            <Route path="/services" exact component={Services} />
            <Route path="/contacts" exact component={Contacts} />
            <Route
              path="/services/:service_id"
              exact
              component={ServiceSingle}
            />
            <Route path="/localisation-form" exact component={LocalisationForm} />
            {/* catch all route  */}
            <Route component={Home} />
          </Switch>
          <MessengerCustomerChat
            pageId="103843797922035"
            appId="306349510393025"
            htmlRef={window.location.pathname}
            language='fr_FR'
            shouldShowDialog={!isMobile}
            loggedOutGreeting="Ecrivez-nous en direct! Comment pouvons-nous vous aider?"
            version="7.0"
          />,
          <Footer />
          <Popup 
            open={isPopupOpen}
            modal
            closeOnDocumentClick
            onClose={() => setIsPopupOpen(false)}
            position="center center"
            contentStyle={{
              width: '90%',
              maxWidth: '600px',
              padding: '30px',
              borderRadius: '5px',
              background: '#fff',
              boxShadow: '0 10px 40px rgba(0,0,0,0.3)'
            }}
            overlayStyle={{
              background: 'rgba(0,0,0,0.85)'
            }}
          >
            <div className="popup-content">
              <button 
                onClick={() => setIsPopupOpen(false)}
                style={{
                  position: 'absolute',
                  right: '15px',
                  top: '15px',
                  background: 'none',
                  border: 'none',
                  fontSize: '24px',
                  cursor: 'pointer',
                  color: '#666',
                  transition: 'color 0.2s',
                  padding: '5px',
                  ':hover': {
                    color: '#333'
                  }
                }}
              >
                ×
              </button>
              <img 
                src="https://primat.nyc3.cdn.digitaloceanspaces.com/primat-logo/primat.svg"
                alt="Primat Logo"
                style={{
                  width: '220px',
                  display: 'block',
                  marginBottom: '10px',
                  marginLeft: '-20px',
                }}
              />
              <h2 style={{ 
                marginBottom: '25px',
                color: '#1a1a1a', 
                textAlign: 'left',
                fontSize: '28px',
                fontWeight: '600'
              }}>
                Annonce importante !
              </h2>
              <p style={{ 
                fontSize: '22px',
                fontWeight: '500',
                color: '#2656ff',
                marginBottom: '25px'
              }}>
                GagnonGodin.ca devient Primat.ca
              </p>
              <p style={{ 
                marginBottom: '25px', 
                lineHeight: '1.6', 
                textAlign: 'left',
                fontSize: '16px',
                color: '#444'
              }}>
               Nous sommes fiers de vous annoncer que GagnonGodin.ca évolue pour devenir Primat.ca.
               Même administration, même dévouement envers l’excellence, avec une vision renouvelée pour mieux vous accompagner.
              </p>
              <p style={{
                fontSize: '16px',
                color: '#444',
                marginBottom: '30px',
                lineHeight: '1.6'
              }}>
                Découvrez tout ce que Primat.ca a à offrir dès aujourd'hui !
                Une nouvelle identité, toujours à votre service.
              </p>
              <div style={{
                display: 'flex',
                gap: '15px',
                justifyContent: 'center'
              }}>
                <button
                  onClick={() => setIsPopupOpen(false)}
                  style={{
                    background: '#ffffff',
                    color: '#2656ff',
                    padding: '12px 25px',
                    border: '2px solid #2656ff',
                    borderRadius: '6px',
                    cursor: 'pointer',
                    fontSize: '16px',
                    fontWeight: '500',
                    transition: 'all 0.2s',
                    ':hover': {
                      background: '#2656ff'
                    }
                  }}
                >
                  Rester sur GagnonGodin
                </button>
                <button
                  onClick={() => {
                    setIsPopupOpen(false);
                    window.location.href = 'https://primat.ca';
                  }}
                  style={{
                    background: '#2656ff',
                    color: 'white',
                    padding: '12px 25px',
                    border: 'none',
                    borderRadius: '6px',
                    cursor: 'pointer',
                    fontSize: '16px',
                    fontWeight: '500',
                    transition: 'background-color 0.2s',
                    ':hover': {
                      background: '#2656ff'
                    }
                  }}
                >
                  Visiter Primat.ca
                </button>
              </div>
            </div>
          </Popup>
        </Fragment>
    );
  }

  return <Fragment>{app}</Fragment>;
};
